import React from "react";

import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Icon } from "../Icon";
import { ErrorTypography } from "../Typography";

type Props = {
  label: string;
  index: number;
  handleUpload: (file: File, fileName: string, index: number) => void;
  imageIconClick: () => void;
  fileInputRef: React.MutableRefObject<HTMLInputElement | null>;
  previewImage: string | ArrayBuffer | null;
  insuranceCardPhoto?: string;
  isError: boolean;
};

export const CardField: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    label,
    index,
    handleUpload,
    imageIconClick,
    fileInputRef,
    previewImage,
    insuranceCardPhoto,
    isError,
  } = props;

  return (
    <Stack spacing={1}>
      <Typography variant="bold14">{label}</Typography>
      <Box
        border="2px dotted"
        borderRadius={2}
        borderColor={isError ? "alert.100" : "func.20"}
        paddingX="36px"
        paddingY="12px"
      >
        <Box display="flex" justifyContent="center" position="relative">
          <Box position="absolute" sx={{ top: "50%", transform: "translate(0, -50%)", }}>
            <IconButton onClick={imageIconClick}>
              <Icon iconName="camera" color="dark.0" size={55} />
            </IconButton>
          </Box>
          {!!previewImage || !!insuranceCardPhoto ? (
            <Box>
              <img
                src={previewImage?.toString() || insuranceCardPhoto}
                alt=""
                width="264px"
                height="auto"
                style={{ minHeight: "177px" }}
              />
            </Box>
          ) : (
            <Box width="264px" height="177px" />
          )}
        </Box>
        <input
          type="file"
          ref={fileInputRef}
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              handleUpload(
                e.target.files[0],
                `insuranceCardPhoto${index}`,
                index
              );
            }
          }}
          capture="user"
          accept="image/*"
          style={{ display: "none" }}
        />
      </Box>
      {isError && <ErrorTypography
        errorText={t(index === 0 ? 'insuranceCard.updateRequired.front' : 'insuranceCard.updateRequired.back')}
        marginTop="0px"
      />
}    </Stack>
  );
};
