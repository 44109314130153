import React, {useEffect, useState} from "react";

import {GENDER} from "constants/gender";
import {PREFECTURES} from "constants/prefecture";
import { isDisplayValidationPrefectureEnabled } from "util/featureFlag";

import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  Button,
  List,
  ListItem,
  ListItemButton,
  Tooltip,
  FormHelperText
} from "@mui/material";
import {useGetZipcodeHook} from "api-client";
import { ErrorIcon } from "components/atoms/Icon/ErrorIcon";
import {RadioGroup} from "components/atoms/RadioGroup";
import {Select} from "components/atoms/Select";
import {TextField} from "components/atoms/TextField";
import {TextFieldWithBirthDay} from "components/atoms/TextFieldWithBirthDay";
import { ErrorTypography } from "components/atoms/Typography";
import {Stepper} from "components/molecules/Stepper";
import { theme } from "providers/ThemeProviderWrap";
import {Control, Controller, FieldErrors, useWatch} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {UserRegisterSchemaType} from "schema/userRegister";

type Props = {
  control: Control<{
    address: string;
    name: string;
    nameKana: string;
    email: string;
    postalCode: string;
    prefecture: string;
    phoneNumber: string;
    gender: "MALE" | "FEMALE";
    birthDay: string;
  }>;
  errors: FieldErrors<{
    address: string;
    name: string;
    nameKana: string;
    email: string;
    postalCode: string;
    prefecture: string;
    phoneNumber: string;
    gender: string;
    birthDay: string;
  }>;
  onSubmit: () => void;
  goBack: () => void;
  disabled: boolean;
  loading: boolean;
  setValue: (name: keyof UserRegisterSchemaType, value: string) => void;
};

export const Presenter: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {control, errors, onSubmit, disabled, loading, setValue} = props;

  const [tooltipContent, setTooltipContent] = useState<React.ReactNode>(null);
  const [focus, setFocus] = useState(false);
  const getZipcode = useGetZipcodeHook();
  const [prefecture, setPrefecture] = useState<string | undefined>(undefined);
  const [isDeviationError, setIsDeviationError] = useState(false);

  const currentPrefecture = useWatch({
    control: control,
    name: "prefecture"
  });

  const currentPostalCode = useWatch({
    control: control,
    name: "postalCode"
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePostalCodeChange = async (v: string) => {
    const postalCode = v;

    if (postalCode.length === 7) {
      try {
        const postalData = await getZipcode({zipcode: v});

        if (postalData && postalData.results && postalData.results.length > 0) {
          if (postalData.results.length > 1) {
            setTooltipContent(
              // TODO: 共通化できるようコンポーネントとして切り出す
              <List>
                {postalData.results.map((result, index) => (
                  <ListItem key={index}>
                    <ListItemButton
                      onClick={() => {
                        setPrefecture(result.prefecture)
                        setValue("prefecture", result.prefecture);
                        setValue("address", result.city + result.town);
                        setFocus(false);
                      }}
                    >
                      {result.prefecture} {result.city} {result.town}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            );
          } else {
            const res = postalData.results[0];
            setPrefecture(res.prefecture)
            setValue("prefecture", res.prefecture);
            setValue("address", res.city + res.town);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    // TODO: https://www.notion.so/119e6cf8d068806a8f31f5bd745be08c?pvs=4 v1.15にて解消
    if(currentPrefecture === undefined || currentPostalCode === undefined || !isDisplayValidationPrefectureEnabled()) return
    
    if(prefecture === undefined) {
      handlePostalCodeChange(currentPostalCode)
    } else {
      prefecture !== currentPrefecture ?  setIsDeviationError(true) : setIsDeviationError(false)
    }
  }, [currentPostalCode, currentPrefecture, handlePostalCodeChange, prefecture])

  return (
    <Box>
      <Box padding={2}>
        <Stepper activeStep={0}/>
      </Box>
      <Typography variant="h2" textAlign="center">
        {t("userRegister.title")}
      </Typography>
      {isDeviationError && (
        <ErrorTypography errorText={t('userRegister.addressError')} />
      )}
      <form onSubmit={onSubmit}>
        <Stack direction="column" spacing={2} justifyContent="center">
          <Controller
            name="name"
            control={control}
            render={({field}) => (
              <TextField
                label={t("userRegister.name.label")}
                type="text"
                required
                placeholder={t("userRegister.name.placeholder")}
                error={!!errors["name"]}
                errorText={errors["name"]?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="nameKana"
            control={control}
            render={({field}) => (
              <TextField
                label={t("userRegister.nameKana.label")}
                type="text"
                placeholder={t("userRegister.nameKana.placeholder")}
                required
                error={!!errors["nameKana"]}
                errorText={errors["nameKana"]?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="birthDay"
            control={control}
            render={({field}) => (
              <TextFieldWithBirthDay
                label={t("userRegister.birthDay.label")}
                type="date"
                defaultValue="1980-01-01"
                required
                error={!!errors["birthDay"]}
                errorText={errors["birthDay"]?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="gender"
            control={control}
            render={({field}) => (
              <RadioGroup
                sx={{width: "50%"}}
                groupLabel={t("userRegister.gender.label")}
                options={GENDER}
                required
                error={!!errors["gender"]}
                errorText={errors["gender"]?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({field}) => (
              <TextField
                label={t("userRegister.email.label")}
                type="email"
                required={false}
                disabled={true}
                placeholder="username@gmail.com"
                error={!!errors["email"]}
                errorText={errors["email"]?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="postalCode"
            control={control}
            render={({field}) => (
              <div
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              >
                <Tooltip title={tooltipContent} open={focus} placement="top">
                  <>
                    <TextField
                      sx={{width: "156%"}} // TODO:
                      label={t("userRegister.postalCode.label")}
                      type="text"
                      required
                      placeholder="1234567"
                      error={!!errors["postalCode"]}
                      errorText={errors["postalCode"]?.message}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        handlePostalCodeChange(e.target.value);
                      }}
                    />
                    <FormHelperText>{t("userRegister.postalCode.helper")}</FormHelperText>
                    {isDeviationError && (
                      <Stack direction='row' alignItems='center' spacing={0.5} marginTop={0}>
                        <ErrorIcon />
                        <Typography variant='caption' color={theme.palette.error.main}>{t("userRegister.postalCode.error")}</Typography>
                      </Stack>
                    )}
                  </>
                </Tooltip>
              </div>
            )}
          />
          <Box sx={{width: '100%'}}>
            <Controller
              name="prefecture"
              control={control}
              defaultValue=""
              render={({field}) => (
                <Select
                  label={t("userRegister.prefecture.label")}
                  type="prefecture"
                  required
                  placeholder={t("userRegister.prefecture.placeholder")}
                  options={PREFECTURES}
                  error={!!errors["prefecture"]}
                  errorText={errors["prefecture"]?.message}
                  {...field}
                  sx={{width: '100%'}}
                />
              )}
            />
            {isDeviationError && (
              <Stack direction='row' alignItems='center' spacing={0.5} marginTop={0}>
                <ErrorIcon />
                <Typography variant='caption' color={theme.palette.error.main}>{t("userRegister.postalCode.error")}</Typography>
              </Stack>
            )}
          </Box>
          <Controller
            name="address"
            control={control}
            render={({field}) => (
              <TextField
                label={t("userRegister.address.label")}
                type="text"
                required
                placeholder={t("userRegister.address.placeholder")}
                error={!!errors["address"]}
                errorText={errors["address"]?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="phoneNumber"
            control={control}
            render={({field}) => (
              <TextField
                label={t("userRegister.phoneNumber.label")}
                type="tel"
                required
                placeholder="08012345678"
                error={!!errors["phoneNumber"]}
                errorText={errors["phoneNumber"]?.message}
                {...field}
              />
            )}
          />
          <Box paddingY="16px">
            <Button
              variant="onboarding"
              color="primary"
              type="submit"
              sx={{width: "100%"}}
              disabled={disabled}
              startIcon={loading ? <CircularProgress size={20}/> : null}
            >
              {t("common.button.next")}
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};
